"use client";

import cn from "clsx";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIntersectionObserver, useWindowSize } from "usehooks-ts";

import { modifyQueryParams } from "../modify-query-params";

import type { ComponentType } from "react";

export interface VimeoPlayerProps {
  autoplay: boolean;
  id: string;
  finishedCallback?: () => void;
  shouldLoad?: boolean;
}

const TAILWIND_XL_WIDTH = 1280;

export type Video = {
  duration: string;
  hidden: boolean;
  video_id: string;
  thumbnail_url: string;
  title: string;
};

export type DecoratedVideo = {
  duration: string;
  hidden: boolean;
  video_id: string;
  selected: boolean;
  thumbnail_url: string;
  title: string;
};

export type VideoCarouselProps = {
  styles?: Record<string, (...rest: Array<unknown>) => string>;
  theme: string;
  videos: Array<Video>;
  VideoPlayerComponent: ComponentType<VimeoPlayerProps>;
};

const LIST_STYLES = cn(
  "overflow-x-auto flex overflow-y-hidden ",
  "xl:snap-none xl:block xl:w-[40%] xl:overflow-y-auto xl:overflow-x-hidden xl:pl-[4%]",
);

const ITEM_STYLES = cn(
  "my-6 mr-6 w-[40%] min-w-[40%] text-sm",
  "lg:w-[30%] lg:min-w-[30%]",
  "xl:m-0 xl:w-full xl:min-w-full xl:text-base",
);

export const VideoCarousel = function VideoCarousel({
  theme,
  videos,
  VideoPlayerComponent,
}: VideoCarouselProps) {
  const windowSize = useWindowSize();
  const router = useRouter();
  const playerRef = useRef(null);
  const iframeWrapperRef = useRef(null);
  const listRef = useRef(null);
  const entry = useIntersectionObserver(playerRef, {
    freezeOnceVisible: true,
  });
  const isVisible = !!entry?.isIntersecting;

  const [autoplay, setAutoPlay] = useState(false);

  const decoratedVideos: Array<DecoratedVideo> = useMemo(
    function () {
      const videoQuery = (router.query["video"] as string) || undefined;

      const videoIsHidden = function (v: Video) {
        return v.hidden
          ? router.query["video"] === v.video_id
            ? false
            : true
          : false;
      };

      const videoIsSelected = function (v: Video) {
        if (!videoQuery) {
          return v.video_id === videos[0]["video_id"];
        } else {
          return v["video_id"] === videoQuery;
        }
      };

      return videos.map((v: Video) => {
        return {
          ...v,
          hidden: videoIsHidden(v),
          selected: videoIsSelected(v),
        };
      });
    },
    [router.query, videos],
  );

  const selectedVideoID = useMemo(
    function () {
      const v = decoratedVideos.find((video) => {
        return video.selected;
      }) as Video;

      return v ? v.video_id : videos[0]["video_id"];
    },
    [decoratedVideos, videos],
  );

  const routeMatch = router.asPath.match(/[^?]*/);
  const baseURL = routeMatch ? routeMatch : "#";

  const setSelectedVideo = function (id: string) {
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          video: id,
        },
      },
      undefined,
      {
        scroll: false,
        shallow: true,
      },
    );
  };

  useEffect(
    function handleResize() {
      if (!listRef.current || !iframeWrapperRef.current || !windowSize.width) {
        return;
      }

      const $list = listRef.current as HTMLUListElement;
      const $wrapper = iframeWrapperRef.current as HTMLDivElement;

      if (windowSize.width >= TAILWIND_XL_WIDTH) {
        $list.style.height =
          Math.max($wrapper.getBoundingClientRect().height, 350) + "px";
      } else {
        $list.style.height = "";
      }
    },
    [windowSize, listRef, iframeWrapperRef],
  );

  const scrollToPlayer = function () {
    if (!playerRef.current) {
      return;
    }

    const $player = playerRef.current as HTMLDivElement;
    window.scrollTo({
      top: $player.getBoundingClientRect().top - 130 + window.scrollY,
      behavior: "smooth",
    });
  };

  useEffect(
    function () {
      const videoQuery = (router.query["video"] as string) || undefined;
      const foundVideo = decoratedVideos.find((video) => {
        return video.video_id === videoQuery;
      });

      if (foundVideo) {
        if (playerRef.current) {
          const wrapper = document.querySelector(".video-carousel-wrapper");
          if (wrapper) {
            wrapper.classList.remove("hidden");
          }
        }
        scrollToPlayer();
        setAutoPlay(true);
      }
    },
    [router, decoratedVideos],
  );

  const visibleVideos = videos.filter((v) => {
    const videoQuery = (router.query["video"] as string) || undefined;
    if (!v.hidden) {
      return true;
    }
    if (videoQuery === v.video_id) {
      return true;
    }
    return false;
  });

  return (
    <div className="xl:flex" ref={playerRef} data-test-id="video-carousel">
      <div
        className={`xl:w-[60%] ${visibleVideos.length > 1 ? "" : "mx-auto"}`}
      >
        <div className="relative aspect-video" ref={iframeWrapperRef}>
          {selectedVideoID && (
            <VideoPlayerComponent
              autoplay={autoplay}
              id={selectedVideoID}
              shouldLoad={isVisible}
            />
          )}
        </div>
      </div>
      {visibleVideos.length > 1 ? (
        <ul className={LIST_STYLES} data-test-id="video-list" ref={listRef}>
          {decoratedVideos.map((video) => {
            return (
              <li
                className={`video-list-item ${
                  video.selected ? "selected" : ""
                } ${ITEM_STYLES} ${video.hidden ? "hidden" : ""}`}
                data-test-id="video-list-item"
                key={video.video_id}
              >
                <a
                  className={`video-list-item-button block w-full text-left xl:flex xl:p-4 xl:items-start ${
                    video.selected ? "selected" : ""
                  }`}
                  data-test-id="video-list-item-button"
                  href={`${baseURL}?video=${video.video_id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedVideo(video.video_id);
                  }}
                >
                  <div className="mb-4 flex items-center xl:mb-0">
                    <img
                      alt={`Video preview for ${video.title}`}
                      className="video-list-item-thumbnail w-full shrink-0 xl:mr-3 xl:h-[56px] xl:w-[100px] xl:min-w-[100px]"
                      data-test-id="video-list-item-thumbnail"
                      src={modifyQueryParams(video.thumbnail_url, {
                        q: 40,
                        mw: 320,
                        mh: 180,
                      }).toString()}
                      width="320"
                      height="180"
                    />
                  </div>

                  <div
                    className="video-list-item-text"
                    data-test-id="video-list-item-text"
                  >
                    {video.title}
                    <br />({video.duration})
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
