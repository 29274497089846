import Head from "next/head";

export const CanonicalLink = function ({ uid }: { uid: string }) {
  let HREF = process.env["NEXT_PUBLIC_PROD_URL"] + "/";
  if (uid && uid !== "index") {
    HREF = HREF + uid;
  }

  return (
    <Head>
      <link rel="canonical" href={HREF} key="canonical" />
    </Head>
  );
};
